import React, { useEffect, useState } from "react";
import { useAntarctica } from "../context/AntarcticaContext";
import gameContent from "../content/game-content.json";
import repairBoatImage from "../assets/images/repair-boat.webp";
import shelterImage from "../assets/images/task-shelter.webp";
import restImage from "../assets/images/task-rest.webp";
import foodImage from "../assets/images/task-food.webp";
import woodImage from "../assets/images/task-wood.webp";
import healthImage from "../assets/images/task-health.webp";
import storeFoodImage from "../assets/images/task-store-food.webp";
import sailAwayImage from "../assets/images/task-sail-away.webp";
import exploreImage from "../assets/images/task-explore.webp";
import "./Tasks.css";

// Create an object to map task imageKeys to their imported images
const taskImages = {
  "repair-boat": repairBoatImage,
  shelter: shelterImage,
  "task-rest": restImage,
  "task-food": foodImage,
  "task-wood": woodImage,
  "task-health": healthImage,
  "task-store-food": storeFoodImage,
  "task-sail-away": sailAwayImage,
  "task-explore": exploreImage,
};

function Tasks({ strategy = "survive" }) {
  const {
    currentRound,
    crewAssignments,
    setCrewAssignment,
    removeCrewAssignment,
    survivors,
  } = useAntarctica();

  // Calculate total assigned workers
  const totalAssignedWorkers = Object.values(crewAssignments)
    .filter((crew) => crew !== null)
    .reduce((sum, crew) => sum + crew.size, 0);

  // Calculate available workers
  const availableWorkers = survivors.current - totalAssignedWorkers;

  useEffect(() => {
    // Calculate total workers assigned across all tasks
    const totalAssignedWorkers = Object.values(crewAssignments)
      .filter((crew) => crew !== null)
      .reduce((sum, crew) => sum + crew.size, 0);

    // Dispatch both events
    document.dispatchEvent(
      new CustomEvent("crewAssignmentsChanged", {
        detail: {
          assignedCrewsCount: totalAssignedWorkers,
        },
      })
    );

    // Dispatch workers state event to trigger the Delegate button
    document.dispatchEvent(
      new CustomEvent("workersStateChanged", {
        detail: {
          allWorkersInCrews: totalAssignedWorkers === survivors.current,
          availableCrews: 0,
        },
      })
    );
  }, [crewAssignments, survivors.current]);

  const handleAddWorker = (taskId) => {
    if (availableWorkers <= 0) return;

    const currentCrew = crewAssignments[taskId];
    const newCrew = {
      id: Date.now(),
      size: currentCrew ? currentCrew.size + 1 : 1,
    };
    setCrewAssignment(taskId, newCrew);
    document.dispatchEvent(new CustomEvent("crewDropped"));
  };

  const handleRemoveWorker = (taskId) => {
    const currentCrew = crewAssignments[taskId];
    if (!currentCrew) return;

    if (currentCrew.size === 1) {
      // Remove the entire crew
      const event = new CustomEvent("crewRemoved", {
        detail: currentCrew,
        bubbles: true,
      });
      document.dispatchEvent(event);
      removeCrewAssignment(taskId);
    } else {
      // Decrease crew size by 1
      const newCrew = {
        ...currentCrew,
        size: currentCrew.size - 1,
      };
      setCrewAssignment(taskId, newCrew);
    }
  };

  const tasks = gameContent.tasks[strategy].map((task) => ({
    ...task,
    locked: task.id === "sail" && currentRound < (task.requiresRound || 0),
    image:
      taskImages[task.imageKey] ||
      "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgZmlsbD0iIzFiM2I1MCIvPjwvc3ZnPg==",
  }));

  return (
    <>
      <div className="tasks__available-workers">
        Available Workers: {availableWorkers}
      </div>
      <div className="tasks">
        {tasks.map((task) => (
          <div
            key={task.id}
            className={`task ${task.locked ? "task--locked" : ""}`}
          >
            <div className="task__content">
              <div className="task__top-section">
                <div className="task__image-container">
                  <img
                    src={task.image}
                    alt={task.title}
                    className="task__image"
                  />
                  <div className="task__image-overlay" />
                </div>
                <div className="task__header">
                  <h3 className="task__title">{task.title}</h3>
                  <div className="task__effects">{task.effects}</div>
                </div>
              </div>
              <div className="task__info">
                <div className="task__crew-controls">
                  <button
                    className="task__crew-button task__crew-button--minus"
                    onClick={() => handleRemoveWorker(task.id)}
                    disabled={!crewAssignments[task.id]}
                  >
                    −
                  </button>
                  <div className="task__crew-count">
                    {crewAssignments[task.id]
                      ? crewAssignments[task.id].size
                      : 0}
                  </div>
                  <button
                    className="task__crew-button task__crew-button--plus"
                    onClick={() => handleAddWorker(task.id)}
                    disabled={availableWorkers <= 0}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <div className="task__frost" />
          </div>
        ))}
      </div>
    </>
  );
}

export default Tasks;
