import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useAthena } from "../../context/AthenaContext";
import { Send } from "lucide-react";
import ChatExchange from "./ChatExchange";
import {
  initialAlert,
  investigationOptions,
  endOptions,
  actions,
  bestPractices,
  demoEndMessage,
} from "../../data/gameContent";

const InvestigationOption = ({
  label,
  description,
  onClick,
  disabled,
  selected,
  completed,
  isEndDemo,
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`px-3 py-1.5 rounded-lg transition-all text-xs flex items-center gap-2 ${
      isEndDemo
        ? "bg-red-600 hover:bg-red-700 text-white"
        : completed
        ? "bg-green-100 text-green-800 hover:bg-green-200"
        : selected
        ? "bg-blue-600 text-white"
        : disabled
        ? "bg-gray-600 opacity-50 cursor-not-allowed"
        : "bg-gray-700 hover:bg-gray-600 text-gray-100 cursor-pointer"
    }`}
  >
    {label}
    {completed && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-3 w-3"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </svg>
    )}
  </button>
);

const MainBoard = forwardRef(({ onInvestigationChange, onKpiUpdate }, ref) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [completedOptions, setCompletedOptions] = useState([]);
  const [completedAction, setCompletedAction] = useState(null);
  const [showEndShortcuts, setShowEndShortcuts] = useState(false);
  const [hasViewedImpact, setHasViewedImpact] = useState(false);
  const messagesEndRef = useRef(null);
  const [messages, setMessages] = useState([initialAlert]);

  const getActionImpact = (actionType) => {
    const action = actions.find((a) => a.id === actionType);
    return action ? action.impact : null;
  };

  const formatKPIMessage = (kpis) => {
    const formatValue = (value, isDollars = false) => {
      const prefix = value > 0 ? "+" : "";
      const suffix = isDollars ? "k USD" : "%";
      return `${prefix}${value}${suffix}`;
    };

    const formatKPILine = (label, value, isDollars = false) => {
      const formattedValue = formatValue(value, isDollars);
      const valueColor =
        label === "Risk Level"
          ? value > 0
            ? "text-red-400"
            : value < 0
            ? "text-green-400"
            : "text-gray-400"
          : value > 0
          ? "text-green-400"
          : value < 0
          ? "text-red-400"
          : "text-gray-400";
      return `${label}: <span class="${valueColor}">${formattedValue}</span>`;
    };

    return (
      `Impact on Key Performance Indicators:\n\n` +
      `${formatKPILine("Reputation", kpis.reputation)}\n` +
      `${formatKPILine("Compliance", kpis.compliance)}\n` +
      `${formatKPILine("Finances", kpis.finances, true)}\n` +
      `${formatKPILine("Risk Level", kpis.risk)}`
    );
  };

  const handleActionSelect = (message) => {
    setInputValue(message);
    if (message.includes("End the demo")) {
      setMessages((prev) => [
        ...prev,
        { isAI: false, message: message },
        {
          isAI: true,
          ...demoEndMessage,
        },
      ]);
    }
  };

  // Expose handleActionSelect to parent
  useImperativeHandle(ref, () => ({
    handleActionSelect,
  }));

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (!inputValue.trim()) return;

    // Add user message to chat
    setMessages((prev) => [...prev, { isAI: false, message: inputValue }]);

    if (inputValue === "End the demo") {
      setTimeout(() => {
        setMessages((prev) => [
          ...prev,
          {
            isAI: true,
            ...demoEndMessage,
          },
        ]);
      }, 1000);
      return;
    }

    // If it's the education option
    if (inputValue.includes("explain the impact")) {
      setMessages((prev) => [...prev, { isAI: true, ...bestPractices }]);
      setShowEndShortcuts(true);
      setHasViewedImpact(true);
      return;
    }

    // If it's a shortcut option
    const selectedInvestigation =
      selectedOption !== null ? investigationOptions[selectedOption] : null;

    if (selectedInvestigation) {
      // Add the current option to completed ones
      setCompletedOptions([...completedOptions, selectedOption]);

      if (selectedInvestigation.label === "Request Additional Documentation") {
        // Add loading message for document request
        setMessages((prev) => [
          ...prev,
          { isAI: true, isLoading: true, type: "docs-loading" },
        ]);

        // After 3 seconds, replace loading with response
        setTimeout(() => {
          setMessages((prev) => {
            const newMessages = prev.filter((msg) => !msg.isLoading);
            return [
              ...newMessages,
              {
                isAI: true,
                message: selectedInvestigation.response,
                type: selectedInvestigation.type,
              },
            ];
          });
        }, 3000);
      } else {
        // For other options, just add the response after a short delay
        setTimeout(() => {
          setMessages((prev) => [
            ...prev,
            {
              isAI: true,
              message: selectedInvestigation.response,
              type: selectedInvestigation.type,
            },
          ]);
        }, 1000);
      }
    } else {
      // If it's an action from the sidebar
      setCompletedAction(inputValue);

      // Find the corresponding action
      const selectedAction = actions.find((action) =>
        inputValue.includes(action.message)
      );

      if (selectedAction) {
        // Initial confirmation
        setTimeout(() => {
          setMessages((prev) => [
            ...prev,
            {
              isAI: true,
              message: selectedAction.initialResponse,
              type: "action",
            },
          ]);

          // Add loading message for impact calculation
          setTimeout(() => {
            setMessages((prev) => [
              ...prev,
              {
                isAI: true,
                message: "Calculating long term impact of your decision...",
                isLoading: true,
                type: "impact-loading",
              },
            ]);

            // After 5 seconds, show the impact analysis
            setTimeout(() => {
              const impact = selectedAction.impact;

              setMessages((prev) => {
                const withoutLoading = prev.filter((msg) => !msg.isLoading);
                return [
                  ...withoutLoading,
                  {
                    isAI: true,
                    message: impact.story,
                    type: "docs",
                  },
                  {
                    isAI: true,
                    message: formatKPIMessage(impact.kpis),
                    type: "docs",
                  },
                ];
              });

              // Trigger KPI updates after showing the impact message
              if (onKpiUpdate) {
                onKpiUpdate(impact.kpis);
              }

              // Show end shortcuts after KPI impact is shown
              setShowEndShortcuts(true);
            }, 5000);
          }, 1000);
        }, 1000);
      }
    }

    // Clear the input and selected option
    setInputValue("");
    setSelectedOption(null);
  };

  const handleOptionSelect = (index, options) => {
    const option = options[index];
    setInputValue(option.message);
    setSelectedOption(index);
    if (!option.type === "end") {
      onInvestigationChange([option.label]);
    }
  };

  return (
    <div className="flex flex-col h-full bg-gray-800">
      {/* Chat messages */}
      <div className="flex-1 relative">
        <ChatExchange messages={messages} />
      </div>

      {/* Controls */}
      <div className="border-t border-gray-700">
        {/* Investigation Options */}
        <div className="px-4 py-2 flex gap-2 items-center border-b border-gray-700">
          <span className="text-gray-400 text-xs">Shortcuts:</span>
          {!showEndShortcuts
            ? investigationOptions.map((option, index) => (
                <InvestigationOption
                  key={index}
                  label={option.label}
                  description={option.description}
                  onClick={() =>
                    handleOptionSelect(index, investigationOptions)
                  }
                  selected={selectedOption === index}
                  disabled={false}
                  completed={completedOptions.includes(index)}
                />
              ))
            : endOptions
                .filter(
                  (option) => !option.type.includes("end") || hasViewedImpact
                )
                .map((option, index) => (
                  <InvestigationOption
                    key={index}
                    label={option.label}
                    description={option.description}
                    onClick={() => handleOptionSelect(index, endOptions)}
                    selected={selectedOption === index}
                    disabled={false}
                    completed={false}
                    isEndDemo={option.type === "end"}
                  />
                ))}
        </div>

        {/* Chat Input */}
        <form onSubmit={handleSendMessage} className="flex gap-2 p-4">
          <input
            type="text"
            value={inputValue}
            readOnly
            placeholder="Select a shortcut above..."
            className="flex-1 bg-gray-700 text-white text-xs rounded px-3 py-2 
                     placeholder-gray-400 focus:outline-none cursor-not-allowed"
          />
          <button
            type="submit"
            disabled={!inputValue}
            className={`text-white p-2 rounded transition-colors flex items-center justify-center
                     ${
                       inputValue
                         ? "bg-blue-600 hover:bg-blue-700 cursor-pointer"
                         : "bg-gray-600 cursor-not-allowed"
                     }`}
          >
            <Send size={16} />
          </button>
        </form>
      </div>
    </div>
  );
});

// Add display name
MainBoard.displayName = "MainBoard";

export default MainBoard;
