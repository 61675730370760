import React, { useRef, useEffect } from "react";
import { Bot, AlertCircle, FileText, Clock } from "lucide-react";

const LoadingDocs = () => (
  <div className="flex items-center gap-3 bg-blue-600/10 border border-blue-600/20 rounded-lg p-4 max-w-[60%]">
    <div className="animate-spin">
      <Clock size={16} className="text-blue-400" />
    </div>
    <div className="flex flex-col gap-1">
      <span className="text-xs font-semibold text-blue-400">
        Waiting for documents...
      </span>
      <div className="w-32 h-1 bg-gray-700 rounded-full overflow-hidden">
        <div
          className="h-full bg-blue-500 rounded-full transition-all duration-3000 ease-in-out"
          style={{
            width: "100%",
            animation: "progress 3s linear",
          }}
        />
      </div>
    </div>
  </div>
);

const LoadingImpact = () => (
  <div className="flex items-center gap-3 bg-blue-600/10 border border-blue-600/20 rounded-lg p-4 max-w-[60%]">
    <div className="animate-spin">
      <Clock size={16} className="text-blue-400" />
    </div>
    <div className="flex flex-col gap-1">
      <span className="text-xs font-semibold text-blue-400">
        Calculating long term impact...
      </span>
      <div className="w-32 h-1 bg-gray-700 rounded-full overflow-hidden">
        <div
          className="h-full bg-blue-500 rounded-full transition-all duration-5000 ease-in-out"
          style={{
            width: "100%",
            animation: "progress 5s linear",
          }}
        />
      </div>
    </div>
  </div>
);

const ChatMessage = ({ message, isAI, isLoading, type }) => (
  <div className={`flex ${isAI ? "justify-start" : "justify-end"} mb-2`}>
    {isAI && !isLoading && (
      <div className="flex flex-col gap-3 max-w-[80%]">
        <div className="flex items-center gap-2">
          <div className="bg-blue-600/20 rounded-lg p-2">
            <Bot size={20} className="text-blue-400" />
          </div>
          <div className="flex flex-col">
            <span className="text-xs font-bold text-blue-400">Athena</span>
            <span className="text-[10px] text-gray-400">
              AI Investigation Assistant
            </span>
          </div>
        </div>
        <div className="bg-blue-600/10 border border-blue-600/20 rounded-lg p-4">
          {type === "alert" ? (
            <div className="flex items-start gap-2 mb-3">
              <AlertCircle size={16} className="text-red-400 mt-0.5" />
              <div className="flex flex-col gap-2">
                <span className="text-xs font-semibold text-red-400">
                  Alert: Suspicious Transaction Pattern
                </span>
                <p className="text-xs leading-relaxed text-gray-100 whitespace-pre-line">
                  {message}
                </p>
              </div>
            </div>
          ) : type === "docs" ? (
            <div className="flex items-start gap-2">
              <FileText size={16} className="text-blue-400 mt-0.5" />
              <div className="flex flex-col gap-2">
                <p
                  className="text-xs leading-relaxed text-gray-100 whitespace-pre-line"
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              </div>
            </div>
          ) : (
            <p className="text-xs leading-relaxed text-gray-100 whitespace-pre-line">
              {message}
            </p>
          )}
        </div>
      </div>
    )}
    {isAI &&
      isLoading &&
      (type === "impact-loading" ? <LoadingImpact /> : <LoadingDocs />)}
    {!isAI && (
      <div className="bg-gray-700 text-white rounded-lg p-3 max-w-[80%]">
        <p className="text-xs leading-relaxed whitespace-pre-line">{message}</p>
      </div>
    )}
  </div>
);

const ChatExchange = ({ messages }) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="absolute inset-0">
      <div className="h-full overflow-y-auto scrollbar">
        <div className="py-4 space-y-4 px-4">
          {messages.map((msg, index) => (
            <ChatMessage
              key={index}
              isAI={msg.isAI}
              message={msg.message}
              isLoading={msg.isLoading}
              type={msg.type}
            />
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <style jsx>{`
        @keyframes progress {
          from {
            width: 0;
          }
          to {
            width: 100%;
          }
        }

        /* Scrollbar Styles */
        .scrollbar::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }

        .scrollbar::-webkit-scrollbar-track {
          background: rgba(30, 41, 59, 0.5);
          border-radius: 4px;
        }

        .scrollbar::-webkit-scrollbar-thumb {
          background: rgba(37, 99, 235, 0.5);
          border-radius: 4px;
          transition: all 0.2s ease-in-out;
        }

        .scrollbar::-webkit-scrollbar-thumb:hover {
          background: rgba(37, 99, 235, 0.7);
        }

        /* Firefox */
        .scrollbar {
          scrollbar-width: thin;
          scrollbar-color: rgba(37, 99, 235, 0.5) rgba(30, 41, 59, 0.5);
        }
      `}</style>
    </div>
  );
};

export default ChatExchange;
