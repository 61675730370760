import React from "react";
import { useAthena } from "../../context/AthenaContext";
import { Shield, Scale, BadgeDollarSign, AlertTriangle } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

const KPIIndicator = ({
  icon: Icon,
  label,
  value,
  color,
  percentage,
  animate,
}) => (
  <div className="flex items-center gap-3 bg-gray-700 rounded-lg px-3 py-2">
    <div className={`text-${color}-400`}>
      <Icon size={20} strokeWidth={1.5} />
    </div>
    <div className="flex flex-col">
      <div className="flex items-center gap-2">
        <span className="text-xs font-medium text-gray-400">{label}</span>
        <motion.span
          key={value}
          initial={animate ? { scale: 1 } : false}
          animate={animate ? { scale: [1, 1.2, 1] } : {}}
          transition={{ duration: 0.5 }}
          className="text-sm font-bold text-white"
        >
          {value}
        </motion.span>
      </div>
      <div className="w-24 h-1 bg-gray-600 rounded-full overflow-hidden">
        <motion.div
          className={`h-1 bg-${color}-500 rounded-full`}
          initial={animate ? { width: 0 } : { width: `${percentage}%` }}
          animate={{ width: `${percentage}%` }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        />
      </div>
    </div>
  </div>
);

const TopBar = ({ kpiUpdates = null }) => {
  const { playerData } = useAthena();

  const kpis = [
    {
      icon: Shield,
      label: "Reputation",
      value: kpiUpdates ? `${kpiUpdates.reputation}%` : "50%",
      color: "blue",
      percentage: kpiUpdates?.reputation ?? 50,
    },
    {
      icon: Scale,
      label: "Compliance",
      value: kpiUpdates ? `${kpiUpdates.compliance}%` : "50%",
      color: "green",
      percentage: kpiUpdates?.compliance ?? 50,
    },
    {
      icon: BadgeDollarSign,
      label: "Finances",
      value: kpiUpdates ? `$${kpiUpdates.finances}k` : "$500K",
      color: "yellow",
      percentage: kpiUpdates?.finances ?? 50,
    },
    {
      icon: AlertTriangle,
      label: "Risk Level",
      value: kpiUpdates ? `${kpiUpdates.risk}%` : "50%",
      color: "red",
      percentage: kpiUpdates?.risk ?? 50,
    },
  ];

  return (
    <div className="bg-gray-800 p-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="bg-blue-600 p-1.5 rounded">
            <Shield size={16} className="text-white" />
          </div>
          <h1 className="text-sm font-bold text-white">
            AML Investigation Unit
          </h1>
        </div>

        <div className="flex gap-3">
          {kpis.map((kpi, index) => (
            <KPIIndicator
              key={index}
              icon={kpi.icon}
              label={kpi.label}
              value={kpi.value}
              color={kpi.color}
              percentage={kpi.percentage}
              animate={kpiUpdates !== null}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
