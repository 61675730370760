import React, { useState, useEffect, useRef } from "react";
import TopBar from "./layout/TopBar";
import RightSidebar from "./layout/RightSidebar";
import MainBoard from "./board/MainBoard";

const SimulationScreen = () => {
  const [scale, setScale] = useState(1);
  const [selectedInvestigations, setSelectedInvestigations] = useState([]);
  const [completedAction, setCompletedAction] = useState(null);
  const [kpiUpdates, setKpiUpdates] = useState(null);
  const mainBoardRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const baseWidth = 1024;
      const baseHeight = 600;
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      const targetWidth = viewportWidth * 0.95;
      const targetHeight = viewportHeight * 0.95;

      const scaleX = targetWidth / baseWidth;
      const scaleY = targetHeight / baseHeight;

      const newScale = Math.min(scaleX, scaleY);
      setScale(newScale);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleActionSelect = (message) => {
    if (mainBoardRef.current?.handleActionSelect) {
      mainBoardRef.current.handleActionSelect(message);
    }
  };

  const handleKpiUpdate = (updates) => {
    // Base values
    const baseValues = {
      reputation: 50,
      compliance: 50,
      finances: 500,
      risk: 50,
    };

    // Calculate new values by adding the impacts to base values
    // Also ensure percentages stay within 0-100 range
    setKpiUpdates({
      reputation: Math.min(
        100,
        Math.max(0, baseValues.reputation + updates.reputation)
      ),
      compliance: Math.min(
        100,
        Math.max(0, baseValues.compliance + updates.compliance)
      ),
      finances: baseValues.finances + updates.finances,
      risk: Math.min(100, Math.max(0, baseValues.risk + updates.risk)),
    });
  };

  return (
    <div className="fixed inset-0 bg-gray-900 overflow-hidden flex items-center justify-center">
      <div
        style={{
          transform: `scale(${scale})`,
          transformOrigin: "center center",
          width: "1024px",
          height: "600px",
        }}
        className="bg-gray-900 flex flex-col p-2 rounded-lg border border-gray-700"
      >
        <div className="border border-gray-700 rounded-lg mb-2">
          <TopBar kpiUpdates={kpiUpdates} />
        </div>
        <div className="flex-1 flex gap-2 h-full">
          <div className="flex-1 border border-gray-700 rounded-lg">
            <MainBoard
              ref={mainBoardRef}
              onInvestigationChange={setSelectedInvestigations}
              onKpiUpdate={handleKpiUpdate}
            />
          </div>
          <div className="border border-gray-700 rounded-lg">
            <RightSidebar
              selectedInvestigations={selectedInvestigations}
              onActionSelect={handleActionSelect}
              completedAction={completedAction}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimulationScreen;
