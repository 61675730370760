// Initial alert message that starts the game
export const initialAlert = {
  isAI: true,
  message:
    "Alert: I've detected a suspicious pattern.\n" +
    "Multiple cash deposits totaling $45,000 were made across different branches within 24 hours.\n\n" +
    "Do you want to investigate this activity?",
  type: "alert",
};

// Investigation options available to the player
export const investigationOptions = [
  {
    label: "Review Client Profile",
    description:
      "Examine the client's background, including business type and tenure.",
    message: "Athena, can you show me the client profile?",
    response:
      "I'll pull up the client profile for you.\n\n" +
      "The account belongs to ABC Trading Corp, a medium-sized import/export business established in 2019.\n" +
      "They typically handle international transactions ranging from $10,000 to $50,000 monthly.",
    type: "profile",
  },
  {
    label: "Request Additional Documentation",
    description:
      "Request supporting documentation for the flagged transaction.",
    message: "Athena, please request additional documentation for this case.",
    response:
      "I've received the requested documents.\n" +
      "Here's what they show:\n\n" +
      "1. Source of Funds Declaration:\n" +
      "   * Funds originate from international trade payments\n" +
      "   * Multiple small businesses listed as payment sources\n\n" +
      "2. Transaction Receipts:\n" +
      "   * Different staff members processed each deposit\n" +
      "   * No clear business reason for splitting deposits\n\n" +
      "3. Business Activity Statement:\n" +
      "   * Recent increase in cash transactions\n" +
      "   * Deviation from usual electronic payment pattern\n\n" +
      "These documents raise additional concerns about the transaction pattern.",
    type: "docs",
  },
  {
    label: "Review Transaction History",
    description:
      "Analyze the client's recent transaction patterns for red flags.",
    message: "Athena, please show me the transaction history for review.",
    response:
      "Here's the transaction history analysis:\n\n" +
      "Last 3 months show average monthly deposits of $25,000.\n" +
      "Recent pattern shows unusual spike in cash deposits.\n" +
      "Multiple branches used within 24 hours.\n" +
      "No previous history of similar behavior.",
    type: "history",
  },
];

// End options shown after taking an action
export const endOptions = [
  {
    label: "Understand the Impact",
    description: "Learn about AML best practices and decision impacts",
    message:
      "Athena, can you explain the impact of my decision and what would have been the best practice?",
    type: "education",
  },
  {
    label: "END Demo",
    description: "End the demonstration",
    message: "End the demo",
    type: "end",
  },
];

// Available actions and their responses
export const actions = [
  {
    label: "No Action Needed",
    id: "no-action",
    message: "Athena, no further action is needed for this case.",
    initialResponse:
      "Understood.\n\nI've marked this case as requiring no further action.\nThe case will be documented and closed.",
    impact: {
      story:
        "Three months later, ABC Trading Corp's unusual transaction patterns continued.\n\n" +
        "Multiple financial institutions reported similar patterns, and regulatory authorities flagged our institution for failing to act on early warning signs.\n" +
        "This oversight has affected our standing in the industry.",
      kpis: {
        reputation: -15,
        compliance: -20,
        finances: -5,
        risk: +15,
      },
    },
  },
  {
    label: "Monitor the Account",
    id: "monitor",
    message: "Athena, please set up account monitoring for this case.",
    initialResponse:
      "I've set up enhanced monitoring for this account.\nWe'll track all transactions and generate weekly reports for review.",
    impact: {
      story:
        "Over the next quarter, our enhanced monitoring system detected a broader pattern of structured deposits across multiple branches.\n" +
        "By proactively tracking these activities, we were able to build a comprehensive risk profile and demonstrate our commitment to compliance.",
      kpis: {
        reputation: +10,
        compliance: +15,
        finances: -5,
        risk: +10,
      },
    },
  },
  {
    label: "File SAR",
    id: "sar",
    message:
      "Athena, I need to file a Suspicious Activity Report for this case.",
    initialResponse:
      "I'll help you prepare the SAR filing.\nI've gathered all the suspicious transactions and relevant customer information for the report.",
    impact: {
      story:
        "The SAR filing proved crucial.\n\n" +
        "Law enforcement contacted us two months later, revealing that our report contributed to a larger investigation into a regional money laundering network.\n" +
        "Our detailed documentation and prompt action were specifically commended by regulators.",
      kpis: {
        reputation: +20,
        compliance: +25,
        finances: -10,
        risk: -20,
      },
    },
  },
  {
    label: "Escalate to Committee",
    id: "escalate",
    message: "Athena, this case needs to be escalated to the committee.",
    initialResponse:
      "Case has been escalated to the committee.\nThey will be notified and will review the case in their next session.",
    impact: {
      story:
        "The committee's review revealed connections to several other high-risk accounts.\n\n" +
        "This led to a bank-wide review of similar business profiles, strengthening our overall risk management framework.\n" +
        "The proactive escalation was noted positively in our annual regulatory assessment.",
      kpis: {
        reputation: +15,
        compliance: +20,
        finances: -8,
        risk: -15,
      },
    },
  },
  {
    label: "Recommend Account Closure",
    id: "close",
    message:
      "Athena, I recommend closing this account due to suspicious activity.",
    initialResponse:
      "I've initiated the account closure process.\nRisk and Compliance teams will be notified, and the customer will receive formal notification.",
    impact: {
      story:
        "Account closure proceedings revealed additional suspicious patterns that were reported to authorities.\n\n" +
        "While we lost some immediate business, our decisive action enhanced our reputation for strong AML controls.\n" +
        "Several new corporate clients cited our strong compliance stance as a reason for choosing our institution.",
      kpis: {
        reputation: +25,
        compliance: +30,
        finances: -15,
        risk: -30,
      },
    },
  },
];

// Educational content about best practices
export const bestPractices = {
  message:
    "In Anti-Money Laundering (AML) compliance, the key is to balance risk management with business operations.\nHere's why:\n\n" +
    "1. Early Detection: Identifying suspicious patterns quickly is crucial.\n" +
    "2. Documentation: Maintaining detailed records protects both the institution and aids law enforcement.\n" +
    "3. Risk Assessment: Each case requires careful evaluation of multiple factors.\n" +
    "4. Regulatory Compliance: Following proper procedures ensures regulatory requirements are met.\n" +
    "In this case, filing a SAR would have been the optimal response due to:\n" +
    "- Multiple transactions just under reporting thresholds\n" +
    "- Use of different branches\n" +
    "- Deviation from normal business patterns\n" +
    "- Lack of clear business justification",
  type: "education",
};

// Demo end message
export const demoEndMessage = {
  message:
    "This interaction demonstrates how a text-based decision making demonstration works. \n" +
    "If you want to find out more, reach out to contact@brainiup.com" +
    "\n\n" +
    "In the actual simulation, the user also has the option to type his messages and use AI for replies.",
  type: "end",
};
