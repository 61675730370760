import React from "react";
import { useAthena } from "../../context/AthenaContext";
import {
  CheckCircle,
  Eye,
  FileText,
  ArrowUpCircle,
  XCircle,
} from "lucide-react";
import { actions } from "../../data/gameContent";

const ActionButton = ({
  label,
  icon: Icon,
  onClick,
  selected,
  completed,
  disabled,
}) => (
  <button
    onClick={onClick}
    disabled={disabled || completed}
    className={`flex-1 flex flex-col items-center justify-center gap-2 transition-all
      ${
        completed
          ? "bg-green-100/20 text-green-400 cursor-not-allowed opacity-50"
          : selected
          ? "bg-blue-600 text-white"
          : disabled
          ? "bg-gray-600 text-gray-400 cursor-not-allowed opacity-50"
          : "bg-gray-700 hover:bg-gray-600 text-gray-100"
      }
      border-b border-gray-600 last:border-b-0
    `}
  >
    <Icon className="w-6 h-6" strokeWidth={1.5} />
    <span className="text-xs font-semibold text-center px-2">{label}</span>
    {completed && (
      <span className="text-[10px] text-green-400">Action Taken</span>
    )}
  </button>
);

const actionIcons = {
  "no-action": CheckCircle,
  monitor: Eye,
  sar: FileText,
  escalate: ArrowUpCircle,
  close: XCircle,
};

const RightSidebar = ({ onActionSelect, completedAction }) => {
  const [selectedAction, setSelectedAction] = React.useState(null);
  const [actionTaken, setActionTaken] = React.useState(false);

  const handleActionSelect = (action) => {
    if (actionTaken || completedAction) return;

    setSelectedAction(action.id);
    setActionTaken(true);
    onActionSelect(action.message);
  };

  return (
    <div className="w-44 h-full">
      <div className="bg-gray-800 rounded-lg h-full flex flex-col">
        {actions.map((action) => (
          <ActionButton
            key={action.id}
            label={action.label}
            icon={actionIcons[action.id]}
            onClick={() => handleActionSelect(action)}
            selected={selectedAction === action.id}
            completed={action.id === completedAction}
            disabled={actionTaken && action.id !== completedAction}
          />
        ))}
      </div>
    </div>
  );
};

export default RightSidebar;
