import React, { useState } from "react";
import CharacterSelector from "./CharacterSelector";
import {
  playIntroMusic,
  fadeOutIntroMusic,
  stopAllSounds,
  playCharacterSelectSound,
} from "../utils/SoundEffects";
import characterJson from "../content/game-content.json";
import "./StartScreen.css";

function StartScreen({ onStart }) {
  const [selectedCharacters, setSelectedCharacters] = useState(new Set());
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState([]);
  const [isFadingOut, setIsFadingOut] = useState(false);

  // Get total number of characters
  const totalCharacters = characterJson.charactersArray.length;

  const messages = [
    "December 5th, 1914. Departing South Georgia Island...",
    "The ice is closing in around the Endurance...",
    "The ship is lost. We must survive on the ice...",
    "Making our way to Elephant Island...",
    "We shall not fail. We shall survive...",
  ];

  const handleStart = async () => {
    setIsLoading(true);
    playIntroMusic();
    setLoadingMessages([]);

    // Show loading messages
    for (let i = 0; i < messages.length; i++) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoadingMessages((prev) => [...prev, messages[i]]);
    }

    // Start fade out
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setIsFadingOut(true);
    fadeOutIntroMusic();

    // Wait for fade out and transition to game
    setTimeout(() => {
      setIsLoading(false);
      onStart(selectedCharacters);
    }, 4000);
  };

  const handleSelectionChange = (selection) => {
    // Play sound when a character is selected
    if (selection.size > selectedCharacters.size) {
      playCharacterSelectSound();
    }
    setSelectedCharacters(selection);
  };

  // Cleanup sounds when component unmounts
  React.useEffect(() => {
    return () => {
      stopAllSounds();
    };
  }, []);

  return (
    <div className={`antarctica-start-screen ${isFadingOut ? "fade-out" : ""}`}>
      {!isLoading ? (
        <>
          <h1 className="antarctica-start-screen__title">Antarctica</h1>
          <CharacterSelector onSelectionChange={handleSelectionChange} />
          <button
            className={`ice-button start-button ${
              selectedCharacters.size !== totalCharacters
                ? "ice-button--disabled"
                : ""
            }`}
            onClick={handleStart}
            disabled={selectedCharacters.size !== totalCharacters}
          >
            <span className="ice-button__content">
              <span className="ice-button__text">
                {selectedCharacters.size === totalCharacters
                  ? "Start"
                  : `Select ${
                      totalCharacters - selectedCharacters.size
                    } more character${
                      selectedCharacters.size === totalCharacters - 1 ? "" : "s"
                    }`}
              </span>
            </span>
            <div className="ice-button__frost" />
          </button>
        </>
      ) : (
        <div className="loading-screen">
          <div className="loading-screen__progress">
            <div className="loading-screen__progress-bar" />
          </div>
          <div className="loading-screen__messages">
            {loadingMessages.map((message, index) => (
              <div
                key={index}
                className="loading-screen__message"
                style={{ animationDelay: `${index * 0.5}s` }}
              >
                {message}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default StartScreen;
