import React, { useState, useEffect } from "react";
import { useAntarctica } from "../context/AntarcticaContext";
import "./Workers.css";

function Workers() {
  const { survivors } = useAntarctica();
  const [assignedToTasks, setAssignedToTasks] = useState(0);

  useEffect(() => {
    const handleCrewAssignmentsChanged = (event) => {
      setAssignedToTasks(event.detail.assignedCrewsCount);
    };

    document.addEventListener(
      "crewAssignmentsChanged",
      handleCrewAssignmentsChanged
    );

    return () => {
      document.removeEventListener(
        "crewAssignmentsChanged",
        handleCrewAssignmentsChanged
      );
    };
  }, []);

  useEffect(() => {
    const handleSurvivorsUpdated = () => {
      setAssignedToTasks(0);
    };

    window.addEventListener("survivorsUpdated", handleSurvivorsUpdated);
    return () => {
      window.removeEventListener("survivorsUpdated", handleSurvivorsUpdated);
    };
  }, []);

  // Calculate available workers
  const availableWorkers = survivors.current - assignedToTasks;

  // Dispatch worker state changes
  useEffect(() => {
    document.dispatchEvent(
      new CustomEvent("workersStateChanged", {
        detail: {
          allWorkersInCrews: availableWorkers === 0,
          availableCrews: 0,
        },
      })
    );
  }, [availableWorkers]);

  return (
    <div className="workers">
      <div className="workers__available">
        Available Workers: {availableWorkers}
      </div>
    </div>
  );
}

export default Workers;
